import { configureStore } from '@reduxjs/toolkit'
import runInDebugMode from '../utils/debug';

import userReducer from '../features/user/userSlice';
import searchReducer from '../features/search/searchSlice';
import uiReducer from '../features/ui/uiSlice';
import statisticsReducer from '../features/statistics/statisticsSlice';
import websitesReducer from '../features/websites/websitesSlice';
import registerDataReducer from '../features/registerData/registerDataSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    search: searchReducer,
    ui: uiReducer,
    statistics: statisticsReducer,
    websites: websitesReducer,
    registerData: registerDataReducer,
  },
});

runInDebugMode(() => (window.getState = store.getState));

export default store;