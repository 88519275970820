
import Dialog from '@mui/material/Dialog';

import './modalWrapper.css';

const ModalWrapper = ({ closeCb, maxWidth, children }) => {

  return (
    <Dialog
      onClose={closeCb}
      open
      classes={{ paper: 'modal-wrapper' }}
      maxWidth={maxWidth}
    >
      {children}
    </Dialog>
  );
};

export default ModalWrapper;
