import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import USER_TYPE from '../../constants/userType';
import httpClient from '../../services/httpClient';
import endpoints from '../../services/httpClient/constants/endpoints';

const initialState = {
  data: null,
  isFetching: true,
  error: null,
};

export const getMyProfile = createAsyncThunk(
  'user/getMyProfile',
  async shouldSetIsFetching => (
    await httpClient.get(endpoints.getMyProfile, { withCredentials: true })
  ),
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getMyProfile.fulfilled, (state, { payload }) => {
      if (payload) {
        const {
          first_name: firstName,
          last_name: lastName,
          type,
          email,
          education_id: educationId,
          activity_id: activityId,
          activity,
          gender,
          year,
          address,
          company_name: companyName,
          has_photo: hasPhoto,
          photo,
          phone_number: phoneNumber,
          status,
        } = payload;
        const lastLoginDate = payload.last_login_date?.date;

        state.data = {
          firstName,
          lastName,
          type,
          email,
          companyName,
          hasPhoto,
          photo,
          phoneNumber,
          status,
          lastLoginDate,
          educationId,
          activityId,
          activity,
          gender,
          year,
          address,
        };
      }

      state.isFetching = false;
    });

    builder.addCase(getMyProfile.pending, (state, { meta }) => {
      state.isFetching = meta.arg;
    });

    builder.addCase(getMyProfile.rejected, (state, { error }) => {
      state.error = error;
      state.isFetching = false;
    });
  },
});

export const userSelector = state => state.user;

export const userDataSelector = createSelector(
  userSelector,
  ({ data }) => data,
);

export const userIsFetchingSelector = createSelector(
  userSelector,
  ({ isFetching }) => isFetching,
);

export const userErrorSelector = createSelector(
  userSelector,
  ({ error }) => error,
);

// personal data
export const typeSelector = state => userDataSelector(state)?.type
export const userNameSelector = state => userDataSelector(state)?.firstName;
export const userLastNameSelector = state => userDataSelector(state)?.lastName;
export const companyNameSelector = state => userDataSelector(state)?.companyName;
export const userGenderSelector = state => userDataSelector(state)?.gender;
export const userEmailSelector = state => userDataSelector(state)?.email;
export const userEducationIdSelector = state => userDataSelector(state)?.educationId;
export const userActivityIdSelector = state => userDataSelector(state)?.activityId;
export const userPhoneNumberSelector = state => userDataSelector(state)?.phoneNumber;
export const userAddressSelector = state => userDataSelector(state)?.address;
export const userYearSelector = state => userDataSelector(state)?.year;

export const userFullNameSelector = state => createSelector(
  [typeSelector, userNameSelector, userLastNameSelector, companyNameSelector],
  (type, firstName, lastName, companyName) => {
    if (Number(type) === USER_TYPE.Personal) {
      return `${firstName} ${lastName}`;
    } else {
      return companyName;
    }
  }
);

// {
//   type: number,
//   first_name: string,
//   last_name: string,
//   gender: number,
//   age: number,
//   email: string,
//   education: number,
//   activity: number,
//   phone_number: ,
//   address: string,
// }

export default userSlice.reducer;
