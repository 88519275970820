import EuropenUnionLogo from '../../../assets/logos/eu.png';
import OpenGovernmentPartnershipLogo from '../../../assets/logos/opg.png';
// import DigitalArmeniaLogo from '../../../assets/logos/da.png';
import { ReactComponent as DigitalArmeniaLogo } from '../../../assets/icons/da-logo.svg';

const Partners = () => {
  return (
    <div className="flex-center">
      <span className="px-3">
        <img src={EuropenUnionLogo} alt="europen union logo" />
      </span>
      <span className="px-3">
        <img src={OpenGovernmentPartnershipLogo} alt="open government partnership" />
      </span>
      <span className="px-3">
        {/* <img src={DigitalArmeniaLogo} alt="digital armenia" /> */}
        <DigitalArmeniaLogo />
      </span>
    </div>
  );
};

export default Partners;