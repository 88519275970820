import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal, modalTypeSelector } from "../../features/ui/uiSlice";
import MODAL_TYPE from '../../constants/modalType';
import LoginModal from './LoginModal/LoginModal';

const ModalsContainer = () => {
  const dispatch = useDispatch();
  const modalType = useSelector(modalTypeSelector);

  const closeCb = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return useMemo(() => {
    switch (modalType) {
      case MODAL_TYPE.Login:
        return <LoginModal closeCb={closeCb} />;
      default:
        return null;
    }
  }, [modalType, closeCb]);
};

export default ModalsContainer;