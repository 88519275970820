const ROUTE_PATHS = {
  Signup: '/sign-up',
  About: '/about-project',
  Statistics: '/statistics',
  ContactUs: '/contact-us',
  SignUp: '/sign-up',
  Websites: '/websites',
  
  WhatWeThink: '/who-we-are/what-we-think',
  ServicesAndProduces: '/who-we-are/services-and-products',
  ECatalog: '/e-catalog',
  ECatalogDetails: '/e-catalog/:alias',
  UpcomingEvents: '/whats-happening/overview',
  OurEvents: '/whats-happening/our-events',
  EventDetails: '/event/:alias',
  MyProfile: '/my-profile',
  ResetPassword: '/reset-password',
  ConfirmAccount: '/confirm-account',
};

export default ROUTE_PATHS;