import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone-icon.svg';
import { ReactComponent as GlobeIcon } from '../../../assets/icons/globe-icon.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/email-icon.svg';
import './contacts.css';

const phoneNumber = '+374 15 333 444';
const websiteLink = 'www.digital-armenia.am';
const email = 'info@digital-armenia.am';

const Contacts = () => (
  <div>
    <div className="flex items-center justify-start">
      <span className="contacts-icon flex-center">
        <PhoneIcon />
      </span>
      <a
        href={`tel:${phoneNumber}`}
        className="pl-2 text-xl text-white"
      >
        {phoneNumber}
      </a>
    </div>
    <div className="flex items-center justify-start pt-8">
      <span className="contacts-icon flex-center">
        <GlobeIcon />
      </span>
      <a
        href={`https://${websiteLink}`}
        target="_blank"
        rel="noreferrer"
        className="pl-2 text-xl text-white"
      >
        {websiteLink}
      </a>
    </div>
    <div className="flex items-center justify-start pt-8">
      <span className="contacts-icon flex-center">
        <EmailIcon className="contacts-email-icon" />
      </span>
      <a
        href={`mailto:${email}`}
        className="pl-2 text-xl text-white"
      >
        {email}
      </a>
    </div>
  </div>
);

export default Contacts;