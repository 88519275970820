import { GENDERS } from '../constants/formValues';
import { emailRegexp, phoneNumberRegexp } from '../constants/regexp';

export const validateEmailValue = value => {
  return emailRegexp.test(value);
};

export const validatePassword = password => {
  const re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
  return re.test(password);
};

export const isFieldValid = value => {
  return !!value.trim().length;
};

export const isValidGender = genderId => (
  !!GENDERS.find(({ id }) => id === genderId)
);

export const isPhoneNumberValid = phone => {
  return phone.match(phoneNumberRegexp);
};
