import { useState } from 'react';
import Popover from '@mui/material/Popover';

import i18n from '../../i18n';
import { ReactComponent as HyFlagIcon } from '../../assets/icons/hy-flag.svg';
import { ReactComponent as RuFlagIcon } from '../../assets/icons/ru-flag.svg';
import { ReactComponent as UsFlagIcon } from '../../assets/icons/us-flag.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';

const languageIcons = [
  { key: 'hy', Icon: HyFlagIcon },
  { key: 'ru', Icon: RuFlagIcon },
  { key: 'en', Icon: UsFlagIcon },
];

const LanguageSwitcher = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const activeLangIcon = languageIcons.find(l => l.key === i18n.language);
  const visibleLangIcons = languageIcons.filter(l => l.key !== i18n.language);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="px-4 flex-center">
      <button
        className="py-4"
        type="button"
        aria-describedby={id}
        onClick={handleClick}
      >
        <activeLangIcon.Icon className="inline-block w-5 h-4" />
        <ArrowDownIcon className="inline-block pl-2 w-5 h-4" />
      </button>
      <Popover
        id={id}
        open={open}
        disableScrollLock
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="flex flex-col bg-white">
          {visibleLangIcons.map(vIcon =>
            <button
              key={vIcon.key}
              className="w-5 h-4 my-2 mx-3"
              onClick={() => i18n.changeLanguage(vIcon.key)}
            >
              <vIcon.Icon />
            </button>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default LanguageSwitcher;
