import i18n from '../i18n';

export const GENDERS = [
  {
    id: 1,
    title: i18n.t('form_gender_male'),
  },
  {
    id: 2,
    title: i18n.t('form_gender_female'),
  },
];

export const MenuListProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      width: 350,
    },
  },
};
