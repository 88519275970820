import environment from "../environments";

let pendingDebugActions = [];
const forceDebugSecret = '7897dhjqghwjdgq%^&^%$%&^$%&88099';
let forceDebugEnabled = false;

const runInDebugMode = fn => {
  if (forceDebugEnabled || environment.debug) {
    fn();
  } else {
    pendingDebugActions.push(fn);
  }
}

window.enableDebug = secret => {
  if (secret === forceDebugSecret) {
    pendingDebugActions.forEach(fn => fn());
    pendingDebugActions = [];

    forceDebugEnabled = true;
  }
};

export default runInDebugMode;