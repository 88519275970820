import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import { ReactComponent as MessageIcon } from '../../assets/icons/message-icon.svg';
import './statusPageWrapper.css';

const StatusPageWrapper = (
  {
    children,
    shouldShowClose,
    closeCb = () => {},
  },
) => {
  return (
    <div className="status-page-wrapper flex-center">
      {shouldShowClose && (
        <button
          type="button"
          className="status-page-wrapper__close w-4 h-4"
          aria-label={'close registration welcome page'}
          onClick={closeCb}
        >
          <CloseIcon />
        </button>
      )}
      <MessageIcon />
      {children}
    </div>
  );
};

export default StatusPageWrapper;
