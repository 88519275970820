import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as ProfileIcon } from '../../../assets/icons/profile-icon.svg';
import { ReactComponent as KeyIcon } from '../../../assets/icons/key-icon.svg';
import i18n, { BASE_URL } from '../../../i18n';
import { userDataSelector } from '../../../features/user/userSlice';
import './authMenu.css';
import MyProfileMenu from './MyProfileMenu/MyProfileMenu';
import { openModal } from '../../../features/ui/uiSlice';
import MODAL_TYPE from '../../../constants/modalType';

const AuthMenu = ({ afterClickCallback }) => {
  const dispatch = useDispatch();
  const userData = useSelector(userDataSelector);

  const openLoginModal = useCallback(() => {
    dispatch(openModal({ type: MODAL_TYPE.Login }));
    afterClickCallback();
  }, [dispatch, afterClickCallback]);

  return (
    <div className="flex menu-item">
      {
        !!userData
          ? <MyProfileMenu afterClickCallback={afterClickCallback} />
          : (
              <>
                <button
                  type="button"
                  className="flex-center login-menu-item"
                  onClick={openLoginModal}
                >
                  <ProfileIcon className="mr-2" />
                  <span>{i18n.t('login')}</span>
                </button>
                <Link
                  to={`${BASE_URL}/sign-up`}
                  className="flex-center pl-3"
                  onClick={afterClickCallback}
                >
                  <KeyIcon className="mr-2" />
                  <span>{i18n.t('registration')}</span>
                </Link>
              </>
          )
      }
    </div>
  );
};

export default AuthMenu;