import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import QUESTION_STATUS from '../../constants/questionnaireStatus';
import QUESTION_TYPE from '../../constants/questionType';

import httpClient from '../../services/httpClient';
import endpoints from '../../services/httpClient/constants/endpoints';
import i18n from '../../i18n';

const initialState = {
  websites: [],
  isFetchingWebsites: false,
  websitesError: null,
  questionnaires: null,
  questions: null,
  visibleQuestions: null,
  isFetchingQuestionnaires: false,
  questionnairesError: null,
  activeQuestionPage: 0,
  activeQuestion: null,
  allQuestionsAnswered: false,
  isCompleted: false,
};

const generateVisibleQuestions = questions => (
  questions.filter(question => (
    shouldBeInVisibleQuestionsList(question, questions)
  ))
);

const shouldBeInVisibleQuestionsList = (question, allQuestions) => {
  const { dependencies } = question;

  if (dependencies?.length) {
    return !isDependantQuestionAnswerChoosed(question, allQuestions);
  }

  return true;
};

const getLatestVisibleQuestion = visibleQuestions => {
  return visibleQuestions.findIndex(question => {
    const { values, type } = question;
    // find a question which does not have
    // any value already choosed or typed if it's a Fillable type
    if (type === QUESTION_TYPE.Fillable) {
      return !question.title?.trim();
    } else {
      return values.every(({ choosed }) => !choosed);
    }
  });
};

const isDependantQuestionAnswerChoosed = (question, allQuestions) => {
  const { dependencies } = question;

  const questionWithDependantAnswer = allQuestions.find(q => {
    const isDependantQuestion = dependencies.some(({ related_question_id: relatedQuestionId }) => (
      relatedQuestionId === q.question_id
    ));

    if (!isDependantQuestion) {
      return false;
    }

    const { values } = q;
    const isDependantAnswerChoosed = dependencies.some(({ related_answer_id: relatedAnswerId }) => (
      values.some(({ id, choosed }) => id === relatedAnswerId && choosed)
    ));

    return isDependantAnswerChoosed;
  });

  return !!questionWithDependantAnswer;
};

export const getWebsites = createAsyncThunk(
  'questionnaire/getWebsites',
  async () => (
    await httpClient.get(endpoints.getWebsites, {
      params: {
        language_prefix: i18n.language,
      }
    })
  ),
);

export const getQuestionnaires = createAsyncThunk(
  'questionnaire/getQuestionnaires',
  async id => (
    await httpClient.get(endpoints.getWebsitesById(id), {
      params: {
        language_prefix: i18n.language,
      }
    })
  ),
);

export const websitesSlice = createSlice({
  name: 'websites',
  initialState,
  reducers: {
    setActiveQuestion: (state, { payload }) => {
      const question = state.visibleQuestions[payload];

      if (question) {
        state.activeQuestionPage = payload;
        state.activeQuestion = question;
      } else {
        state.activeQuestionPage = state.visibleQuestions.length - 1;
        state.activeQuestion = state.visibleQuestions[state.activeQuestionPage];
        state.allQuestionsAnswered = true;
      }
    },
    updateAnswerData: (state, { payload }) => {
      const { id, choosed, value } = payload;
      const isActiveQuestionFillable = state.activeQuestion.type === QUESTION_TYPE.Fillable;

      if (isActiveQuestionFillable) {
        const activeValuesData = state.activeQuestion.values[0] ?? {};
        const valuesObject = {
          ...activeValuesData,
          title: value,
        };

        state.activeQuestion = {
          ...state.activeQuestion,
          values: [valuesObject],
        };
      } else {
        state.activeQuestion = {
          ...state.activeQuestion,
          values: state.activeQuestion.values.map(answer => ({ 
            ...answer,
            choosed: (
                !Number(state.activeQuestion.multiple_answer)
                  ? (answer.id === id ? choosed : 0)
                  : (answer.id === id ? choosed : answer.choosed)
              ),
           })),
        };
      }
      
      state.questions = state.questions.map(question => {
        if (question.question_id === state.activeQuestion.question_id) {
          return state.activeQuestion;
        }

        return question;
      });
      state.visibleQuestions = generateVisibleQuestions(state.questions);
    },
    setLastActiveQuestion: (state) => {
      state.allQuestionsAnswered = false;
    },
    setIsCompleted: (state) => {
      state.isCompleted = true;
    },
    resetQuestionnaire: state => {
      state.questionnaires = null;
      state.questions = null;
      state.visibleQuestions = null;
      state.isFetchingQuestionnaires = false;
      state.questionnairesError = null;
      state.activeQuestionPage = 0;
      state.activeQuestion = null;
      state.allQuestionsAnswered = false;
      state.isCompleted = false;
    },
  },
  extraReducers: builder => {
    // Websites
    builder.addCase(getWebsites.pending, state => {
      state.isFetchingWebsites = true;
    });
    builder.addCase(getWebsites.fulfilled, (state, { payload }) => {
      state.websites = payload;
      state.isFetchingWebsites = false;
    });
    builder.addCase(getWebsites.rejected, (state, { error }) => {
      console.log('websites rejected: ', error);
      state.websitesError = error;
      state.isFetchingWebsites = false;
    });

    // Questionnaires
    builder.addCase(getQuestionnaires.pending, state => {
      state.isFetchingQuestionnaires = true;
    });
    builder.addCase(getQuestionnaires.fulfilled, (state, { payload }) => {
      const { questionnaires, status } = payload;

      // complete the Questionnaire and
      // show completed content
      if (status === QUESTION_STATUS.Completed) {
        state.isCompleted = true;
        return;
      }

      state.questionnaires = questionnaires;

      state.questions = state.questionnaires.reduce((pValue, cValue) => {
        const { questions } = cValue;
        pValue.push(...questions);

        return pValue;
      }, []);
      state.visibleQuestions = generateVisibleQuestions(state.questions);

      let activeQuestionIndex;
      if (status === QUESTION_STATUS.NotStarted) {
        activeQuestionIndex = 0;
      } else {
        activeQuestionIndex = getLatestVisibleQuestion(state.visibleQuestions);
      }

      // if all Questions are answered
      // show proper message
      if (activeQuestionIndex === -1) {
        state.allQuestionsAnswered = true;
        return; 
      }

      state.activeQuestion = state.visibleQuestions[activeQuestionIndex];
      state.activeQuestionPage = activeQuestionIndex;
      state.isFetchingQuestionnaires = false;
    });
    builder.addCase(getQuestionnaires.rejected, (state, { error }) => {
      state.questionnairesError = error;
      state.isFetchingQuestionnaires = false;
    });
  },
});

export const {
  setActiveQuestion,
  setLastActiveQuestion,
  setIsCompleted,
  updateAnswerData,
  resetQuestionnaire,
} = websitesSlice.actions;

export const websitesSelector = state => state.websites;

// Websites selectors
export const websitesDataSelector = createSelector(
  websitesSelector,
  ({ websites }) => websites,
);

export const completedWebsitesSelector = createSelector(
  websitesDataSelector,
  websites => (
    websites.filter(({ status }) => status === QUESTION_STATUS.Completed)
  ),
);

export const pendingWebsitesSelector = createSelector(
  websitesDataSelector,
  websites => (
    websites.filter(({ status }) => status === QUESTION_STATUS.Pending)
  ),
);

export const notStartedWebsitesSelector = createSelector(
  websitesDataSelector,
  websites => (
    websites.filter(({ status }) => status === QUESTION_STATUS.NotStarted)
  ),
);

export const isFetchingWebsitesSelector = createSelector(
  websitesSelector,
  ({ isFetchingWebsites }) => isFetchingWebsites,
);

export const websitesErrorSelector = createSelector(
  websitesSelector,
  ({ websitesError }) => websitesError,
);

// Questionnaires selectors
export const questionnairesSelector = createSelector(
  websitesSelector,
  ({ questionnaires }) => questionnaires,
);

export const isFetchingQuestionnairesSelector = createSelector(
  websitesSelector,
  ({ isFetchingQuestionnaires }) => isFetchingQuestionnaires,
);

export const questionnairesErrorSelector = createSelector(
  websitesSelector,
  ({ questionnairesError }) => questionnairesError,
);

export const activeQuestionPageSelector = createSelector(
  websitesSelector,
  ({ activeQuestionPage }) => activeQuestionPage,
);

export const activeQuestionSelector = createSelector(
  websitesSelector,
  ({ activeQuestion }) => activeQuestion,
);

export const allQuestionsAnsweredSelector = createSelector(
  websitesSelector,
  ({ allQuestionsAnswered }) => allQuestionsAnswered,
);

export const isCompletedSelector = createSelector(
  websitesSelector,
  ({ isCompleted }) => isCompleted,
);

export const activeQuestionnaireSelector = createSelector(
  questionnairesSelector,
  activeQuestionSelector,
  (data, activeQuestion) => (
    data?.find(
      questionnaire => (
        questionnaire.questions.find(({ question_id }) => (
          question_id === activeQuestion?.question_id
        ))
      )
    )
  ),
);

export const lastActiveQuestionIndexSelector = createSelector(
  websitesSelector,
  ({ visibleQuestions }) => {
    return visibleQuestions?.findIndex(question => {
      const { values, type } = question;
      // find a question which does not have
      // any value already choosed or typed if it's a Fillable type
      if (type === QUESTION_TYPE.Fillable) {
        return !question.title?.trim();
      } else {
        return values.every(({ choosed }) => !choosed);
      }
    });
  },
);

export const questionnaireQuestionsSelector = createSelector(
  websitesSelector,
  ({ questions }) => questions,
);

export const visibleQuestionsSelector = createSelector(
  websitesSelector,
  ({ visibleQuestions }) => visibleQuestions,
);

export const isActiveQuestionAnswerChoosedSelector = createSelector(
  websitesSelector,
  ({ activeQuestion }) => {
    if (!activeQuestion) {
      // in case if there is no questions and no answers
      return false;
    }

    const { values, type } = activeQuestion;
    const isFillableQuestion = type === QUESTION_TYPE.Fillable;

    return isFillableQuestion
      ? values[0]?.title?.trim()
      : values.some(({ choosed }) => choosed);
  },
);

export default websitesSlice.reducer;
