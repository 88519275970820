import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'

import httpClient from '../../services/httpClient';
import endpoints from '../../services/httpClient/constants/endpoints';
import i18n from '../../i18n';

const initialState = {
  activities: [],
  educations: [],
};

export const fetchActivities = createAsyncThunk(
  'registerData/fetchActivities',
  async () => (
    await httpClient.get(endpoints.activities, {
      params: {
        language_prefix: i18n.language,
      }
    })
  ),
);

export const fetchEducations = createAsyncThunk(
  'registerData/fetchEducations',
  async () => (
    await httpClient.get(endpoints.educations, {
      params: {
        language_prefix: i18n.language,
      }
    })
  ),
);

export const registerDataSlice = createSlice({
  name: 'registerData',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // activities
    builder.addCase(fetchActivities.fulfilled, (state, { payload }) => {
      state.activities = payload;
    });

    builder.addCase(fetchActivities.pending, (state) => {
      // console.log('activiities pending: ');
    });

    builder.addCase(fetchActivities.rejected, (state, { error }) => {
      console.log('activities rejected: ', error);
    });

    // educations
    builder.addCase(fetchEducations.fulfilled, (state, { payload }) => {
      state.educations = payload;
    });

    builder.addCase(fetchEducations.pending, (state) => {
      // console.log('educations pending: ');
    });

    builder.addCase(fetchEducations.rejected, (state, { error }) => {
      console.log('educations rejected: ', error);
    });
  },
});

export const registerDataSelector = state => state.registerData;

export const registerDataActivitiesSelector = createSelector(
  registerDataSelector,
  registerData => registerData.activities,
);

export const registerDataEducationsSelector = createSelector(
  registerDataSelector,
  registerData => registerData.educations,
);

export default registerDataSlice.reducer;
