import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { ReactComponent as SmallCloseIcon } from '../../../assets/icons/small-close-icon.svg';
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import LoginImage from '../../../assets/auth/login-image.png';
import i18n from '../../../i18n';
import LoginForm from "./LoginForm/LoginForm";
import FORM_TYPE from './constants/formType';
import ForgotPasswordForm from "./ForgotPasswordForm/ForgotPasswordForm";
import './loginModal.css';

const LoginModal = ({ closeCb }) => {
  const [activeFormType, setActiveFormType] = useState(FORM_TYPE.Login);

  const switchFormType = useCallback(type => {
    setActiveFormType(type);
  }, []);

  return (
    <ModalWrapper closeCb={closeCb} maxWidth="md">
      <div className="flex bg-trueGrey-600 relative">
        <div className="hidden sm:block">
          <img src={LoginImage} className="h-full" alt="login modal" />
        </div>
        <div className="login-form">
          <h4 className="login-header">
            {
              activeFormType === FORM_TYPE.Login
                ? i18n.t('login')
                : i18n.t('login_change_password')
            }
          </h4>
          {activeFormType === FORM_TYPE.Login && (
            <LoginForm switchForm={switchFormType} />
          )}
          {activeFormType === FORM_TYPE.ForgotPassword && (
            <ForgotPasswordForm switchForm={switchFormType} />
          )}
        </div>
        <button
          type="button"
          className="small-close-button flex-center"
          onClick={closeCb}
          aria-label="login modal close button"
        >
          <SmallCloseIcon />
        </button>
      </div>
    </ModalWrapper>
  );
};

export default LoginModal;