import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';

import httpClient from '../../services/httpClient';
import endpoints from '../../services/httpClient/constants/endpoints';
import i18n from '../../i18n';

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};

export const fetchStatistics = createAsyncThunk(
  'statistics/getStatistics',
  async () => (
    await httpClient.get(endpoints.statistics, {
      params: {
        language_prefix: i18n.language,
      }
    })
  ),
);

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStatistics.fulfilled, (state, { payload }) => {
      console.log('fulfilled: ', payload);
      state.data = payload;
      state.isLoading = false;
    });

    builder.addCase(fetchStatistics.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchStatistics.rejected, (state, { error }) => {
      console.log('rejected: ', error);
      state.error = error;
      state.isLoading = false;
    });
  },
});

export const statisticsSelector = state => state.statistics;

export const statisticsDataSelector = createSelector(
  statisticsSelector,
  ({ data }) => data,
);

export const statisticsIsLoadingSelector = createSelector(
  statisticsSelector,
  ({ isLoading }) => isLoading,
);

export const statisticsErrorSelector = createSelector(
  statisticsSelector,
  ({ error }) => error,
);

export default statisticsSlice.reducer;