import axios from 'axios';
import environment from '../../environments';
import { HTTP_ERROR_CODE } from './constants/codes';
import endpoints from './constants/endpoints';

const AUTHENTICATION_URLS = [
  'my-profile',
  'my-profile/update',
  'login',
  'register',
];

let retryCounter = 0;

const httpClient = axios.create({
  baseURL: environment.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const oauthApiClient = axios.create({
  baseURL: environment.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

oauthApiClient.interceptors.response.use(({ data }) => data);

const getAuthToken = () => {
  return oauthApiClient.post(endpoints.auth, {
    client_id: environment.clientId,
    client_secret: environment.clientSecret,
    grant_type: environment.grantType,
    scope: '',
  });
};

export const authorized = token => {
  httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

httpClient.interceptors.request.use(
  (config) => {
    const accessToken = localStorage?.getItem('token');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  });

httpClient.interceptors.response.use(
  ({ data }) => {
    retryCounter = 0;
    return data;
  },
  error => {
    const { response, config } = error;
    const status = response?.status;

    if (status === HTTP_ERROR_CODE.Unauthenticated) {
      if (retryCounter < 1) {
        return getAuthToken()
          .catch(() => {
            // eventEmitter.emit(REDIRECT_TO_AUTH)
          })
          .then(({ access_token: token }) => {
            authorized(token);
            localStorage.setItem('token', token);

            // We need to set token to avoid overriding with old token
            config.headers['Authorization'] = `Bearer ${token}`;

            retryCounter++;
            return httpClient.request(config);
          });
      }

      // todo:: implement error handling
      // in case if Auth api is down
      // eventEmitter.emit(REDIRECT_TO_AUTH);
    }

    return Promise.reject(getMappedError(response));
  },
);

const getMappedError = error => (
  error
    ? {
      status: error.status,
      statusText: error.statusText,
      data: error.data || null,
    }
    : {
      status: HTTP_ERROR_CODE.NetworkError,
      statusText: 'Network Error',
      data: null,
    }
);

export default httpClient;
