const endpoints = {
  auth: 'oauth2/auth',
  teamMembers: 'team/members',
  servicesList: 'e-tools/services',
  caseStudies: 'case-studies',
  caseStudyTypes: 'case-study-types',
  getEvents: 'e-tools/events',
  getEventDetails: alias => `e-tools/events/${alias}`,
  getEventSets: 'e-tools/event-sets/main',
  suggestions: 'suggestions',
  getTopicsList: 'e-tools/topics',
  getEventTypesList: 'e-tools/event-types',
  getCatalogs: 'e-tools/catalogs',
  getCatalogDetails: alias => `e-tools/catalogs/${alias}`,
  getCategories: 'e-tools/categories',
  getVacancies: 'vacancies',
  register: 'register',
  confirmRegistration: 'confirm',
  login: 'login',
  logout: 'logout',
  getMyProfile: 'my-profile',
  updateProfile: 'my-profile/update',
  getMySubscriptions: 'my-subscriptions',
  updateMySubscriptions: 'my-subscriptions/update',
  uploadImage: "my-profile/upload",
  getQuestionnaires: 'questionnaires',
  getQuestionnairesById: id =>  `questionnaires/${id}`,
  getWebsites: 'websites',
  getWebsitesById: id => `websites/${id}`,
  getQuestionnaire: item => `questionnaires/${item}`,
  sendQuestionAnswers: 'questions/answer',
  saveDraft: 'questions/draft',
  forgotPassword: "forgot",
  resetPassword: 'confirm-password',
  statistics: 'stats',
  activities: 'activities',
  educations: 'educations',
};

export default endpoints;
