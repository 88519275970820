import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { isMediumViewSelector } from "../../features/ui/uiSlice";
import i18next, { BASE_URL } from "../../i18n";
import CloseButton from "../CloseButton/CloseButton";
import AuthMenu from "./AuthMenu/AuthMenu";
import Hamburger from "./Hamburger/Hamburger";
import './menu.css';

const Menu = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isMediumView = useSelector(isMediumViewSelector);

  const onHamburgerClick = useCallback(() => {
    setIsCollapsed(prevState => !prevState);
  }, []);

  const closeMobileMenu = useCallback(() => {
    setIsCollapsed(false);
  }, []);

  return (
    <div className="menu flex-center">
      <div className="lg:hidden flex justify-start absolute left-4">
        <Hamburger onClickHandler={onHamburgerClick} />
      </div>

      {(!isMediumView || (isMediumView && isCollapsed)) && (
        <nav className="home-nav py-4">
          <Link
            to={BASE_URL + "/"}
            className="menu-item"
            onClick={closeMobileMenu}
          >
            {i18next.t('menu_home')}
          </Link>
          <Link
            to={BASE_URL + "/about-project"}
            className="menu-item"
            onClick={closeMobileMenu}
          >
            {i18next.t('menu_about')}
          </Link>
          <Link
            to={BASE_URL + "/statistics"}
            className="menu-item"
            onClick={closeMobileMenu}
          >
            {i18next.t('menu_statistics')}
          </Link>
          <Link
            to={BASE_URL + "/contact-us"}
            className="menu-item"
            onClick={closeMobileMenu}
          >
            {i18next.t('menu_contact')}
          </Link>
          <AuthMenu afterClickCallback={closeMobileMenu} />

          {(isMediumView && isCollapsed) && (
            <CloseButton
              closeCb={closeMobileMenu}
              className="right-4 top-4 w-6 h-6"
            />
          )}
        </nav>
      )}
    </div>
  );
};

export default Menu;