
import { useCallback, useState } from 'react';
import { Button, TextField } from '@mui/material';

import { ReactComponent as LoginEmailIcon } from '../../../../assets/icons/email-icon.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right.svg';
import httpClient from '../../../../services/httpClient';
import endpoints from '../../../../services/httpClient/constants/endpoints';
import { validateEmailValue } from '../../../../utils/validator';
import i18n from '../../../../i18n';
import Loader from '../../../Loader/Loader';
import FORM_TYPE from '../constants/formType';
import StatusPageWrapper from '../../../StatusPageWrapper/StatusPageWrapper';

import './forgotPassword.css';

const ForgotPasswordForm = ({ switchForm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);

  const handleEmailChange = useCallback(({ target: { value } }) => {
    setIsEmailInvalid(false);

    if (value.trim().length <= 250) {
      setEmail(value);
    }
  }, []);

  const handleEmailBlur = useCallback(() => {
    setIsEmailInvalid(!validateEmailValue(email.trim()));
  }, [email]);

  const handleSubmit = useCallback(async e => {
    e.preventDefault();

    // to trigger input field error
    handleEmailBlur();

    const emailValue = email.trim();
    if (validateEmailValue(emailValue)) {
      try {
        setIsLoading(true);
        setErrorText(null);

        await httpClient.post(endpoints.forgotPassword, { email: emailValue });
        setShowSuccessMessage(true);
      } catch (error) {
        setErrorText(i18n.t('generic_error_text'));
      } finally {
        setIsLoading(false);
      }
    }
  }, [email, handleEmailBlur]);

  return (
    <div className="w-full pt-4">
      {errorText && (
        <p className="text-sm text-red-500 text-center pb-4">{errorText}</p>
      )}
      {
        showSuccessMessage
          ? (
            <StatusPageWrapper>
              <h2
                className="mt-6 text-white text-md text-center px-5"
                dangerouslySetInnerHTML={{ __html: i18n.t('forgot_password_success_text') }}
              />
            </StatusPageWrapper>
          )
          : (
            <form>
              <div
                className="w-full flex mb-4"
              >
                <span className="login-form-icon flex-center">
                  <LoginEmailIcon className="h-full" />
                </span>
                <TextField
                  id="loginEmail"
                  variant="outlined"
                  placeholder={i18n.t('login_email')}
                  size="small"
                  classes={{
                    root: `form-input forgot-form-field ${isEmailInvalid ? 'form-input__invalid' : ''}`,
                    popper: 'shadow-md',
                  }}
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={handleEmailBlur}
                />
              </div>
              <div className="flex-center flex-col">
                <Button
                  type="submit"
                  id="loginSubmit"
                  onClick={handleSubmit}
                  classes={{ root: 'button-primary w-40 bg-primary-700 !text-white' }}
                >
                  {
                    isLoading
                      ? <Loader diameter={30} borderWidth={3} />
                      : i18n.t('forgot_password_button')
                  }
                </Button>
                <Button
                  type="button"
                  classes={{ root: '!mt-2 text-base text-blue-500' }}
                  onClick={() => switchForm(FORM_TYPE.Login)}
                >
                  <ArrowRight className="rotate-180 fill-primary-80" />
                  <span className="pl-1">{i18n.t('back')}</span>
                </Button>
              </div>
            </form>
          )
      }
    </div>
  );
};

export default ForgotPasswordForm;
