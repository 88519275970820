import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ROUTE_PATHS from '../../../../../constants/paths';
import { modalDataSelector } from '../../../../../features/ui/uiSlice';
import { BASE_URL } from '../../../../../i18n';

const AUTH_PAGES_PATHS = [
  ROUTE_PATHS.Signup,
  ROUTE_PATHS.ResetPassword,
  ROUTE_PATHS.ConfirmAccount,
];

const useLoginRedirection = () => {
  const modalData = useSelector(modalDataSelector);
  const { pathname } = useLocation();

  return useCallback(() => {
    if (AUTH_PAGES_PATHS.some(path => pathname?.includes(path))) {
      window.location.assign(BASE_URL);
    } else if (modalData?.redirectionUrl) {
      window.location.assign(`${BASE_URL}${modalData.redirectionUrl}`);
    } else {
      window.location.reload();
    }
  }, [pathname, modalData]);
};

export default useLoginRedirection;
