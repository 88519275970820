import { Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import "./i18n";
import AppRoutes from './AppRoutes/AppRoutes';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import useLegacyEffect from './hooks/useLegacyEffect/useLegacyEffect';
import { setWindowDimensions } from './features/ui/uiSlice';
import ModalsContainer from './components/ModalsContainer/ModalsContainer';
import { getMyProfile } from './features/user/userSlice';

function App() {
  const dispatch = useDispatch();

  useLegacyEffect(() => {
    dispatch(getMyProfile(true));
  }, [dispatch]);

  useLegacyEffect(() => {
    const handleResize = () => {
      dispatch(setWindowDimensions({ width: window.innerWidth, height: window.innerHeight }));
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [dispatch]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>E Monitoring</title>
        <meta
          name="description"
          content="E Monitoring description"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Router>
        <Suspense fallback={null}>
          <main className="main-content">
            <Header />
            <AppRoutes />
            <Footer />
          </main>
          <ModalsContainer />
        </Suspense>
      </Router>
    </HelmetProvider>
  );
}

export default App;
