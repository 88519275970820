import classNames from "classnames";

import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import './closeButton.css';

const CloseButton = ({ closeCb, className = '' }) => (
  <button
    type="button"
    className={classNames('close-button', className)}
    onClick={closeCb}
    aria-label="close button"
  >
    <CloseIcon className="close-button__icon" />
  </button>
);

export default CloseButton;