
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormGroup, TextField } from '@mui/material';

import i18n, { BASE_URL } from '../../i18n';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { searchValueSelector, setSearchValue } from '../../features/search/searchSlice';

import './search.css';

const Search = ({ className = '' }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchValue = useSelector(searchValueSelector);

  const placeholder = `${i18n.t('search')}...`;

  const handleSearch = useCallback(e => {
    e.preventDefault();

    if (!!searchValue.trim().length) {
      navigate(`${BASE_URL}/e-catalog?s=${searchValue.trim()}`);
    }
  }, [searchValue, navigate]);

  const handleSearchChange = ({ target: { value } }) => {
    const valueLength = value.trim().length;

    if (valueLength < 250) {
      dispatch(setSearchValue(value));
    }
  }

  return (
    <form onSubmit={handleSearch} className={className}>
      <FormGroup
        classes={{
          root: 'search-form-group relative',
        }}
        row
      >
        <TextField
          variant="outlined"
          size="small"
          placeholder={placeholder}
          classes={{
            root: 'search-input',
            popper: 'shadow-md',
          }}
          value={searchValue}
          onChange={handleSearchChange}
        />
        <Button
          variant="outlined"
          type="submit"
          size="small"
          aria-label="search button"
          classes={{
            root: 'search-button border'
          }}
        >
          <SearchIcon className="search-icon" />
        </Button>
      </FormGroup>
    </form>
  );
};

export default Search;