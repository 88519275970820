import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import useLegacyEffect from '../../hooks/useLegacyEffect/useLegacyEffect';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import Menu from '../Menu/Menu';
import Search from '../Search/Search';
import './header.css';
import ROUTE_PATHS from '../../constants/paths';

const Header = () => {
  const { pathname } = useLocation();
  const [isPageScrolled, setIsPageScrolled] = useState(false);

  const shouldSetDefaultBg = useMemo(() => {
    const pathItems = pathname.split('/');

    return !!pathItems[2] && ROUTE_PATHS.ContactUs.includes(pathItems[2]);
  });

  useLegacyEffect(() => {
    const handleScroll = () => {
      setIsPageScrolled(window.scrollY >= 50);
    };

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    };
  }, []);

  return (
    <header 
      className={classNames('header', {
        'header-bg': shouldSetDefaultBg || isPageScrolled
      })}
    >
      <div className="page-container flex justify-end">
        <div className="max-w-screen-page flex">
          <Menu />
          <LanguageSwitcher />
          <Search className="flex-center py-3" />
        </div>
      </div>
    </header>
  );
};

export default Header;