import Contacts from "./Contacts/Contacts";
import Partners from "./Partners/Partners";
import SocialInfo from "./SocialInfo/SocialInfo";
import './footer.css';

const Footer = () => {

  return (
    <footer className="footer">
      <div className="page-container page-content flex-center">
        <div className="w-full max-w-screen-page flex flex-col md:flex-row justify-between">
          <div>
            <SocialInfo />
            <div className="py-8 md:pt-16">
              <Partners />
            </div>
          </div>
          <div className="flex justify-center md:justify-start">
            <Contacts />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;