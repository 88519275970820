import httpClient from "../services/httpClient";
import endpoints from "../services/httpClient/constants/endpoints";

const logout = async () => {
  try {
    await httpClient.delete(endpoints.logout);
  } catch (error) {
    // todo:: handle error
  } finally {
    window.location.reload();
  }
};

export default logout;
