import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import ROUTE_PATHS from '../constants/paths';
const Home = lazy(() => import('../pages/Home/Home'));
const NotFound = lazy(() => import('../pages/Home/NotFound/NotFound'));
const About = lazy(() => import('../pages/About/About'));
const Statistics = lazy(() => import('../pages/Statistics/Statistics'));
const Websites = lazy(() => import('../pages/Websites/Websites'));
const Website = lazy(() => import('../pages/Websites/pages/Website/Website'));
const Questionnaire = lazy(() => import('../pages/Websites/pages/Questionnaire/Questionnaire'));
const ContactUs = lazy(() => import('../pages/ContactUs/ContactUs'));
const SignUp = lazy(() => import('../pages/Auth/SignUp/SignUp'));
const ConfirmAccount = lazy(() => import('../pages/Auth/ConfirmAccount/ConfirmAccount'));
const MyProfile = lazy(() => import('../pages/MyProfile/MyProfile'));
const ResetPassword = lazy(() => import('../pages/Auth/ResetPassword/ResetPassword'));

const AppRoutes = () => {
  const baseRouteUrl = `/:locale`;

  return (
    <div
      className="main-pages-content flex-auto"
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path={baseRouteUrl} element={<Home />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATHS.About}`} element={<About />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATHS.Websites}`} element={<Websites />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATHS.Websites}`} element={<Websites />} >
          <Route path="" element={<Website />} />
          <Route path=":id" element={<Questionnaire />} />
        </Route>
        <Route path={`${baseRouteUrl}${ROUTE_PATHS.Statistics}`} element={<Statistics />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATHS.ContactUs}`} element={<ContactUs />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATHS.SignUp}`} element={<SignUp />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATHS.ConfirmAccount}`} element={<ConfirmAccount />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATHS.MyProfile}`} element={<MyProfile />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATHS.ResetPassword}`} element={<ResetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default AppRoutes;
