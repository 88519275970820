import { createSlice, createSelector } from '@reduxjs/toolkit'

const initialState = {
  value: '',
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchValue: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const {
  setSearchValue,
} = searchSlice.actions;

// export const searchSelector = createSelector(
//   state => state.search,
// );

export const searchValueSelector = createSelector(
  state => state.search,
  search => search.value,
);

export default searchSlice.reducer;