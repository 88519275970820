import { useCallback, useState } from 'react';
import { Button, FormGroup, TextField } from '@mui/material';

import { ReactComponent as LoginEmailIcon } from '../../../../assets/icons/email-icon.svg';
import { ReactComponent as LoginLockIcon } from '../../../../assets/icons/lock-icon.svg';
import { validateEmailValue } from '../../../../utils/validator';
import i18n from '../../../../i18n';
import FORM_TYPE from '../constants/formType';
import httpClient from '../../../../services/httpClient';
import endpoints from '../../../../services/httpClient/constants/endpoints';
import useLoginRedirection from './useLoginRedirection/useLoginRedirection';
import Loader from '../../../Loader/Loader';
import './loginForm.css';

const LoginForm = ({ switchForm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const [email, setEmail] = useState('');
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);

  const navigateAfterLogin = useLoginRedirection();

  const handleEmailChange = useCallback(({ target: { value } }) => {
    setIsEmailInvalid(false);

    if (value.trim().length <= 250) {
      setEmail(value);
    }
  }, []);

  const handleEmailBlur = useCallback(() => {
    setIsEmailInvalid(!validateEmailValue(email.trim()));
  }, [email]);

  const handlePasswordChange = useCallback(({ target: { value } }) => {
    setIsPasswordInvalid(false);

    if (value.trim().length <= 250) {
      setPassword(value);
    }
  }, []);

  const handlePasswordBlur = useCallback(() => {
    setIsPasswordInvalid(!password.trim().length);
  }, [password]);

  const triggerValidations = useCallback(() => {
    handleEmailBlur();
    handlePasswordBlur();
  }, [handleEmailBlur, handlePasswordBlur]);

  const handleSubmit = useCallback(async e => {
    e.preventDefault();

    const emailValue = email.trim();
    const passwordValue = password.trim();

    // trigger validations to show invalid states
    // after submitting form
    triggerValidations();

    if (
      validateEmailValue(emailValue)
      && passwordValue
    ) {
      try {
        setIsLoading(true);
        setErrorText(null);

        await httpClient.post(endpoints.login, {
          email: emailValue,
          password: passwordValue,
        });

        navigateAfterLogin();
      } catch (error) {
        const { data } = error;

        if (data?.detail?.code === 608) {
          setErrorText(i18n.t('incorrect_email_or_password'));
        } else {
          setErrorText(i18n.t('generic_error_text'));
        }
      } finally {
        setIsLoading(false);
      }
    }
  }, [triggerValidations, email, password, navigateAfterLogin]);

  return (
    <div className="flex flex-col">
      {errorText && (
        <span className="text-red-600 text-sm">{errorText}</span>
      )}
      <form>
        <div className="flex pt-2">
          <span className="login-form-icon flex-center">
            <LoginEmailIcon className="h-full" />
          </span>
          <TextField
            id="loginEmail"
            variant="outlined"
            size="small"
            placeholder={i18n.t('form_email')}
            classes={{
              root: `form-input login-form-field ${isEmailInvalid ? 'form-input__invalid' : ''}`,
              popper: 'shadow-md',
            }}
            value={email}
            onChange={handleEmailChange}
            onBlur={handleEmailBlur}
          />
        </div>
        <div className="flex pt-2">
          <span className="login-form-icon flex-center">
            <LoginLockIcon />
          </span>
          <TextField
            id="loginPassword"
            variant="outlined"
            type="password"
            size="small"
            placeholder={i18n.t('form_password')}
            classes={{
              root: `form-input login-form-field ${isPasswordInvalid ? 'form-input__invalid' : ''}`,
              popper: 'shadow-md',
            }}
            value={password}
            onChange={handlePasswordChange}
            onBlur={handlePasswordBlur}
          />
        </div>
        <div className="flex justify-end pt-2">
          <button
            type="button"
            className="text-primary-700 text-xs font-light"
            onClick={() => switchForm(FORM_TYPE.ForgotPassword)}
          >
            {i18n.t('login_forgot_password')}
          </button>
        </div>
        <div className="flex w-full justify-end pt-8">
          <Button
            type="submit"
            id="loginSubmit"
            onClick={handleSubmit}
            classes={{ root: 'button-primary w-40 bg-primary-700 !text-white' }}
          >
            {
              isLoading
                ? <Loader diameter={30} borderWidth={3} />
                : i18n.t('login')
            }
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
