import { ReactComponent as FacebookIcon } from '../../../assets/icons/fb-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/icons/linkedin-icon.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/instagram-icon.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/icons/youtube-icon.svg';
import './socialInfo.css';

const facebookLink = 'https://www.facebook.com/profile.php?id=100082975226715';
const linkedinLink = 'https://www.linkedin.com/company/digital-armenia/';
const instagramLink = 'https://www.instagram.com/digital_armenia/';
const youtubeLink = 'https://www.youtube.com/channel/UCy_GGdbEwfGPGGq_tRavydA';

// todo:: ask UX designer for instagram icon

const SocialInfo = () => {
  return (
    <div className="flex justify-center md:justify-start">
      <a
        href={facebookLink}
        target="_blank"
        rel="noreferrer"
        className="social-link px-4"
      >
        <FacebookIcon />
      </a>
      <a
        href={linkedinLink}
        target="_blank"
        rel="noreferrer"
        className="social-link px-4"
      >
        <LinkedinIcon />
      </a>
      <a
        href={instagramLink}
        target="_blank"
        rel="noreferrer"
        className="social-link px-4"
      >
        <InstagramIcon />
      </a>
      <a
        href={youtubeLink}
        target="_blank"
        rel="noreferrer"
        className="social-link px-4"
      >
        <YoutubeIcon />
      </a>
    </div>
  );
};

export default SocialInfo;