import { createSlice, createSelector } from '@reduxjs/toolkit'

import { MEDIUM_WINDOW_WIDTH } from '../../constants/windowSize';

const initialState = {
  modalType: null,
  modalData: null,
  windowDimensions: {
    width: null,
    height: null,
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openModal: (state, { payload }) => {
      state.modalType = payload.type;
      // to not set undefined, just to keep null value as no data provided
      state.modalData = payload.data ?? null;
    },
    closeModal: state => {
      state.modalType = null;
      state.modalData = null;
    },
    setWindowDimensions: (state, { payload }) => {
      state.windowDimensions = {
        width: payload.width,
        height: payload.height,
      };
    },
  },
});

export const {
  openModal,
  closeModal,
  setWindowDimensions,
} = uiSlice.actions;

const uiSelector = state => state.ui;

export const windowDimensionsSelector = createSelector(
  uiSelector,
  ui => ui.windowDimensions,
);

export const widthSelector = createSelector(
  windowDimensionsSelector,
  dimensions => dimensions.width,
);

export const heightSelector = createSelector(
  windowDimensionsSelector,
  dimensions => dimensions.height,
);

export const modalTypeSelector = createSelector(
  uiSelector,
  ({ modalType }) => modalType,
);

export const modalDataSelector = createSelector(
  uiSelector,
  ({ modalData }) => modalData,
);

export const isMediumViewSelector = state => widthSelector(state) < MEDIUM_WINDOW_WIDTH;

export default uiSlice.reducer;