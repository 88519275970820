const PROD_ENV = {
  debug: false,
  baseUrl: 'https://e-monitoring.am/',
  apiUrl: 'https://api.e-monitoring.am/',
  cdnUrl: 'https://cdn.e-monitoring.am/',
  clientId: '20162017',
  clientSecret: 'dsahdsa7&^&^jdlsfdsfdsfdsakdlsakldksadnsa90',
  grantType: 'client_credentials',
};

export default PROD_ENV;